import * as React from "react";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.heroImage);

  return (
    <Layout>
      {/* header start */}
      <div className="overflow-hidden bg-yellow-400 px-6 pb-28 pt-6 sm:px-0">
        <div className="relative mx-auto max-w-lg">
          {/* nav start */}
          <span className="flex gap-4">
            <Link
              to="/"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              Sahil Khan
            </Link>
            <Link
              to="/about"
              className="text-base font-semibold text-black/50 hover:text-black hover:underline">
              About
            </Link>
            <Link to="/blog" className="text-base font-semibold text-black">
              Blog
            </Link>
          </span>
          {/* nav end */}
          {/* <img
            src={illustration}
            alt="illustration of Sahil Khan"
            className="absolute w-96 -top-6 -right-52"
          /> */}
        </div>
      </div>
      {/* header end */}

      {/* body start */}
      <div className="mx-auto max-w-lg px-6 pt-12 sm:px-0">
        <main className="prose prose-invert prose-a:text-gray-400 hover:prose-a:text-gray-200 prose-code:rounded-sm prose-code:bg-red-900 prose-code:px-0.5 prose-code:text-red-200 prose-code:before:content-none prose-code:after:content-none prose-hr:border-gray-800">
          <GatsbyImage
            image={image}
            alt={data.mdx.frontmatter.heroImageAlt}
            className="-mt-32 mb-12 aspect-[3/2] rounded-2xl border border-white/10 [&>*>*]:rounded-2xl"
          />
          <h1>{data.mdx.frontmatter.title}</h1>
          <p className="mb-4 text-sm">
            {data.mdx.frontmatter.datePublished}{" "}
            {data.mdx.frontmatter.dateUpdated && (
              <span>(Updated: {data.mdx.frontmatter.dateUpdated})</span>
            )}
          </p>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </main>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        description
        datePublished(formatString: "MMMM D, YYYY")
        dateUpdated(formatString: "MMMM D, YYYY")
        heroImageAlt
        heroImageCreditText
        heroImageCreditLink
        heroImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`;

export default BlogPost;

export const Head = ({
  data: {
    mdx: {
      frontmatter: {
        title,
        description,
        heroImage: {
          childImageSharp: {
            gatsbyImageData: {
              images: {
                fallback: { src },
              },
            },
          },
        },
      },
    },
  },
}) => {
  return <Seo title={title} description={description} image={src} />;
};
